<template>
	<div style="padding:0px; margin-bottom: -128px;">
		<v-row no-gutters class="text-center" style="margin-top:-128px; height:128px;">
			<v-col cols="12" style="background-color:#303030;">
			</v-col>
		</v-row>
		<div style="max-width:816px; margin:auto;">
			<div class="bg" v-if="artPiece!=null && artPiecesOk">
				<p align="center" style="font-size: 34pt; margin-bottom: 0cm; letter-spacing: normal; line-height: 1.7em; padding-top:50px;" class="didotItalicFont">
					<em><span style="font-weight: normal; letter-spacing: 12px;">Certificado</span></em>
				</p>
				<p align="center" style="font-size: 34pt; margin-bottom: 0cm; letter-spacing: normal; font-style: normal; line-height: 0.2cm; text-decoration: none" class="didotFont">
					<b style="letter-spacing: 12px;">de Autenticidad</b>
					<br/><br/><br/><br/><br/><br/><br/>
				</p>
				<p align="center" style="margin-bottom: 0cm; letter-spacing: normal; font-style: normal; text-decoration: none">
					<img :src="require(`../assets/${getImage(artPiece.title)}`)" name="Image2" align="center" height="387" border="0"/>
					<br/><br/>
				</p>
				<p align="center" style="font-size: 20pt; letter-spacing: 3px; margin-bottom: 0cm; font-style: normal; line-height: 2.5em" class="didotFont">
					<b>{{getArtPiece().title.split("|")[languageId]}}</b>
				</p>
				<p align="center" style="font-size: 14pt; letter-spacing: 1px; padding-top: 5px; margin-bottom: 0cm; font-weight: normal; line-height: 1.7em" class="didotItalicFont">
					{{getArtPiece().history.split("|")[languageId]}}
				</p>
				<p align="center" style="margin-bottom: 0cm; line-height: 1.5em">
					<br/>
					<br/>
				</p>
				<p align="center" style="font-size: 16pt; letter-spacing: 1.5px; margin-bottom: 0cm; font-weight: normal; line-height: 1.8em" class="didotItalicFont">
					Yo, Karlus Morales, certifico que la obra &ldquo;{{getArtPiece().title.split("|")[languageId]}}&rdquo; pertenece a la colecci&oacute;n &ldquo;{{getCollection()}}&rdquo; y es una obra original realizada por &ldquo;<strong class="didotItalicFont">Karlus </strong><span class="didotFont">Morales</span>&rdquo;, quien garantiza la autenticidad de la misma y la veracidad de las descripciones contempladas en este certificado.
				</p>
				<p align="center" style="margin-bottom: 0cm; line-height: 1.5em">
					<br/>
					<br/>
					<br/>
					<br/>
				</p>
				<v-row no-gutters>
					<v-col cols="6" class="text-right">
						<p style="line-height:1.5em; font-size: 16pt; " class="didotFont">
							<b>Autor :</b>
						</p>
					</v-col>
					<v-col cols="6" align-self="end">
						<p style="font-size: 13pt;" class="pl-2 pb-1 didotFont">
							<strong class="didotItalicFont">Karlus </strong><span>Morales</span>
						</p>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="6" class="text-right">
						<p style="line-height:1.5em; font-size: 16pt; " class="didotFont">
							<b>T&eacute;cnica :  </b>
						</p>
					</v-col>
					<v-col cols="6" align-self="end">
						<p style="font-size: 13pt;" class="pl-2 pb-1 didotItalicFont">
							Fotograf&iacute;a digital
						</p>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="6" class="text-right">
						<p style="line-height:1.5em; font-size: 16pt; " class="didotFont">
							<b>A&ntilde;o :  </b>
						</p>
					</v-col>
					<v-col cols="6" align-self="end">
						<p style="font-size: 13pt;" class="pl-2 pb-1 didotItalicFont">
							{{xIfDate}}
						</p>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="6" class="text-right">
						<p style="line-height:1.5em; font-size: 16pt; " class="didotFont">
							<b>Dimensiones :  </b>
						</p>
					</v-col>
					<v-col cols="6" align-self="end">
						<p style="font-size: 13pt;" class="pl-2 pb-1 didotItalicFont">
							{{getSize().split("|")[languageId]}}
						</p>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="6" class="text-right">
						<p style="line-height:1.5em; font-size: 16pt; " class="didotFont">
							<b>Tipo de papel :  </b>
						</p>
					</v-col>
					<v-col cols="6" align-self="end">
						<p style="font-size: 13pt;" class="pl-2 pb-1 didotItalicFont">
							{{paperType}}
						</p>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="6" class="text-right">
						<p style="line-height:1.5em; font-size: 16pt; " class="didotFont">
							<b>Numeraci&oacute;n :  </b>
						</p>
					</v-col>
					<v-col cols="6" align-self="end">
						<p style="font-size: 13pt;" class="pl-2 pb-1 didotItalicFont">
							{{limitedEdition}}
						</p>
					</v-col>
				</v-row>
				<v-row no-gutters>
					<v-col cols="6" class="text-right">
						<p style="line-height:1.5em; font-size: 16pt; " class="didotFont">
							<b>Ubicaci&oacute;n firma :  </b>
						</p>
					</v-col>
					<v-col cols="6" align-self="end">
						<p style="font-size: 13pt;" class="pl-2 pb-1 didotItalicFont">
							Al reverso
						</p>
					</v-col>
				</v-row>
				<p align="center" style="margin-bottom: 0cm; line-height: 1.5em">
					<br/>
				</p>
				<p align="center" style="margin-bottom: 0cm; line-height: 1.5em; padding-bottom:30px;" class="didotItalicFont">
					Referencia {{qr}} emitido el {{creationDate}}
				</p>
			</div>
		</div>
		<footerComponent :backgroundImage="footerImage"></footerComponent>
	</div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import footerComponent from './components/Footer'
const baseURL = process.env.VUE_APP_BASE_URL_API;
const captchaKey = process.env.VUE_APP_CAPTCHA_KEY;
	export default {
		name: 'QR',
		components: { footerComponent },
		data: () => ({
			pageTitle:['Certificados Karlus Morales','Certificates Karlus Morales'],
			pageMetaTitle:['Certificados Karlus Morales','Certificates Karlus Morales'],
			pageDescription:['Certificación de autenticidad de obras de Karlus Morales','Autenticity certificates of Karlus Morales art pieces'],
            pageKeywords:['Certificados','Certificates'],
            metaImage:'',
			footerImage:require('../assets/About/About_Desktop_06.jpg'),
			titleLabel:["Título", "Title"],
			certificateLabel:["Certificado", "Certificate"],
            qr:"",
			artPiece:null,
			creationDate:'',
			collection:'',
			description:'',
			limitedEdition:'',
			paperType:'',
			xIfDate:'',
        }),
        computed: {
            ...mapState("shoppingCart", 
                [
                    "languageId", "metricSystemId", "images", "artPieces", "artPiecesOk","collectionNames",
					"size", "printedMedium", "frame", "liner",  "propertiesOk", 
                    "privacyPolicyLabel", "termsOfUseLabel", "homeButtonLabel", "artPiecesButtonLabel", "aboutButtonLabel", "contactButtonLabel",
                    "sizeLabel","printedMediumLabel","frameLabel","linerLabel", "sizeHint", "serialNumberLabel",
                ]),
			isSmallScreen(){
				let screenType = "Desktop";
				if(this.displaySizeClass == 'mobile-size')
					screenType = "Mobile";
				if(this.displaySizeClass == 'tablet-size')
					screenType = "Tablet";
				return screenType
			},
			displaySizeClass(){
				let displayClass = "desktop-size";
				let windowWidth = this.widthSize;
				if(windowWidth <= 640)
					displayClass = "mobile-size";
				if(windowWidth >= 1024 && windowWidth <= 1440)
					displayClass = "tablet-size";
				return displayClass
			},
			widthSize(){
				let windowWidth = window.innerWidth;
				if(windowWidth < 320)
					windowWidth = 320
				if(windowWidth > 640 && windowWidth < 1024)
					windowWidth = 640
				if(windowWidth > 1440 && windowWidth < 1920)
					windowWidth = 1440
				if(windowWidth > 1920)
					windowWidth = 1920
				return windowWidth
			},
			title(){
				if(this.artPiece == null) return '';
				return this.artPiece.title.split("|")[this.languageId];
			}
        },
		metaInfo(){
			return{
				title: this.pageTitle[this.languageId],
				meta: [
					{
					vmid: 'robots',
					name: 'robots',
					content: 'noindex, nofollow',
					},
					{
					vmid: 'title',
					name: 'title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'description',
					name: 'description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'keywords',
					name: 'keywords',
					content: this.pageKeywords[this.languageId],
					},
					{
					vmid: 'og:title',
					property: 'og:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'og:description',
					property: 'og:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'og:image',
					property: 'og:image',
					content: this.metaImage,
					},
					{
					vmid: 'og:url',
					property: 'og:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:title',
					name: 'twitter:title',
					content: this.pageMetaTitle[this.languageId],
					},
					{
					vmid: 'twitter:description',
					name: 'twitter:description',
					content: this.pageDescription[this.languageId],
					},
					{
					vmid: 'twitter:image',
					name: 'twitter:image',
					content: this.metaImage,
					},
					{
					vmid: 'twitter:url',
					name: 'twitter:url',
					content: `${window.location.href}`,
					},
					{
					vmid: 'twitter:card',
					name: 'twitter:card',
					content: 'summary',
					},
				]
			}
		},
		async mounted () {
			let query = this.$route.query;
			this.qr = query.qr;
            await this.loadRecaptcha(document, "script", "google-recaptcha");
			this.submit();
		},
		beforeDestroy(){
			document.getElementsByClassName("grecaptcha-badge")[0].style.display="none";
		},
		methods: {
			getArtPiece(){
				let artPiece = this.artPieces.find(x=>x.title.includes(this.artPiece?.title))
				return artPiece;
			},
			getCollection(){
				let artPiece = this.getArtPiece()
				let collection = this.collectionNames.find(x=>x.id == artPiece?.idCollection)
				return collection?.name.split("|")[this.languageId]
			},
			getImage(title){
				let artPiece = this.artPieces.find(x=>x.title.includes(title))
				let images = this.images.find(x=>x.idArtPiece == artPiece.id)
				return images?.base64
			},
            async loadRecaptcha(d, s, id) {
                let js,
                    fjs = d.getElementsByTagName(s)[0];
                if (d.getElementById(id)) {
					d.getElementsByClassName("grecaptcha-badge")[0].style.display="block";
                    return;
                }
                js = d.createElement(s);
                js.id = id;
                js.src = "https://www.google.com/recaptcha/api.js?render="+captchaKey;
                fjs.parentNode.insertBefore(js, fjs);
            },

			submit(){
				setTimeout(()=>{
					if(window.grecaptcha==undefined) return;
					let self = this;
					window.grecaptcha.ready(function() {
						window.grecaptcha.execute(captchaKey, {action: 'submit'})
							.then((token) => {
								self.sendRequest(token);
							})
							.catch(console.log("Captcha"));
					})
				},300)
			},
			sendRequest(token){
				if(token!= null){
					axios.get(`${baseURL}certificate/${this.qr}?captcha=${token}`)
						.then(res => {
							if(res.status == 200){
								this.artPiece = res.data;
								this.getCollection()
							}
						})
						.catch(error => { 
							console.log('sendRequest',error);
						});
				}
			},

			getSize(){
				let value = this.size.find(x=>x.id == parseInt(this.artPiece.size));
				return value.name;
			},
			getPrintedMedium(){
				let value = this.printedMedium.find(x=>x.id == parseInt(this.artPiece.printedMedium));
				return value.name;
			},
			getFrame(){
				let value = this.frame.find(x=>x.id == parseInt(this.artPiece.frame));
				return value.name;
			},
			getLiner(){
				let value = this.liner.find(x=>x.id == parseInt(this.artPiece.liner));
				return value.name;
			},
		},
	}
</script>
<style scoped>
p { 
	margin-bottom: 0.25cm; 
	line-height: 115%; 
	background: transparent;
	margin: 0px;
}
.bg {
	margin: auto;
	width: fit-content;
	height: 100%; 
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
}
</style>